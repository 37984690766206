import { Trans } from "react-i18next";
import { LockOpenIcon } from "@heroicons/react/24/outline";

import Navigation from "components/UserInterface/Navigation";

const ResetPassword = function ResetPasswordPage() {
  return (
    <div className="flex flex-col grow">
      <Navigation />
      <div className="flex flex-col grow justify-center gap-8 p-4">
        <LockOpenIcon className="w-24 h-24 text-black self-center" />
        <h1 className="text-lg sm:text-xl font-semibold text-center">
          <Trans i18nKey="resetPassword.text">
            To reset your password, please send an email to{" "}
            <a className="underline text-blue-500" href="mailto:contact@respeakable.com">
              contact@respeakable.com
            </a>
            .
          </Trans>
        </h1>
      </div>
    </div>
  );
};

export default ResetPassword;
