import React, { useMemo, useState } from "react";

import useSpeechSynth from "components/useSpeechSynth";
import { MediumTypeProps } from "./types";
import { ArrowsPointingOutIcon, XMarkIcon } from "@heroicons/react/24/outline";

interface ImageData {
  description: string;
  file: string;
}

interface ImageMediumProps extends MediumTypeProps {
  data: ImageData;
}

const ImageMedium: React.FC<ImageMediumProps> = function ImageMediumComponent({ data, speechSynthID }) {
  const { description, file } = useMemo(() => data, [data]);
  const [zoomIn, setZoomIn] = useState<boolean>(false);

  const { onSpeechSynthComplete } = useSpeechSynth({
    interrupt: () => {},
    speechSynthID,
    trigger: () => onSpeechSynthComplete(),
  });

  return (
    <div className="flex justify-center py-2">
      <div className="relative group/img" onClick={() => setZoomIn(true)}>
        <img alt={description} className="h-40 cursor-pointer" src={file} />
        <div className="absolute top-1 right-1 p-0.5 cursor-pointer hidden group-hover/img:block bg-black rounded-md bg-opacity-50">
          <ArrowsPointingOutIcon className="w-5 h-5 text-white stroke-[2]" />
        </div>
      </div>
      {zoomIn && (
        <>
          <div className="fixed inset-0 bg-black opacity-30 z-50" onClick={() => setZoomIn(false)} />
          <div className="fixed group/big inset-2 md:right-auto md:bottom-auto flex items-center md:w-max md:h-max justify-center p-3 md:left-1/2 md:top-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 rounded-md bg-gray-100 z-50">
            <img alt={description} className="self-stretch md:w-auto md:h-[80vh] mx-auto" src={file} />
            <div
              className="absolute top-2 right-2 shadow-md cursor-pointer md:hidden group-hover/big:block bg-black rounded-sm bg-opacity-30"
              onClick={() => setZoomIn(false)}
            >
              <XMarkIcon className="w-6 h-6 text-white stroke-[2.5]" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageMedium;
