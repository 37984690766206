import React, { useCallback, useMemo, useState } from "react";
import { ForwardIcon, PlayIcon } from "@heroicons/react/24/solid";

import { ReadText } from "components/Text";
import { MediumTypeProps } from "./types";
import classNames from "classnames";
import { SpeechSynthProvider } from "components/useSpeechSynth";
import TranslateLine from "../Exercise/shared/TranslateLine";
import { XMarkIcon, LanguageIcon } from "@heroicons/react/24/outline";

interface DialogueData {
  lines: { speaker: number; text: string }[];
  speakers: { id: string; name: string }[];
}

interface DialogueMediumProps extends MediumTypeProps {
  data: DialogueData;
}

const DialogueMedium: React.FC<DialogueMediumProps> = function DialogueMediumComponent({ data }) {
  const [speechLineIndex, setSpeechLineIndex] = useState<number>(Number.MAX_VALUE);
  const [playLine, setPlayLine] = useState<number>(-1);
  const [showTranslation, setShowTranslation] = useState<number>(-1);

  const { lines, speakers } = useMemo(() => data, [data]);

  const triggerLine = (index: number) => {
    setShowTranslation(-1);
    setPlayLine(index);
    setSpeechLineIndex(index);
  };

  const handleSpeechEnd = useCallback(() => {
    if (playLine !== -1) {
      setPlayLine(-1);
      setSpeechLineIndex(Number.MAX_VALUE);
      return;
    }

    setSpeechLineIndex((index) => index + 1);
  }, [setSpeechLineIndex, speechLineIndex, playLine]);

  return (
    <SpeechSynthProvider activeID={`${speechLineIndex}`} onComplete={handleSpeechEnd}>
      <div>
        {lines.map((line, index) => (
          <div
            className={classNames("flex relative", {
              "select-none pointer-events-none": index > speechLineIndex,
            })}
            key={index}
          >
            <div className={classNames("w-28 shrink-0 select-none font-bold relative")}>
              {speakers[line.speaker].name}:
            </div>
            <div className="flex flex-row flex-wrap justify-end grow group/line">
              {showTranslation === index && <TranslateLine phrase={line.text} className="grow mr-3" />}
              <ReadText
                speakerID={speakers[line.speaker].id}
                speechSynthID={`${index}`}
                text={line.text}
                displayAll={speechLineIndex > lines.length || (playLine !== -1 && playLine !== index)}
                className={classNames("grow inline mr-4", { hidden: showTranslation === index })}
              />
              <div className="flex flex-row gap-2">
                {showTranslation !== index ? (
                  <>
                    <button
                      disabled={speechLineIndex < lines.length}
                      onClick={() => setShowTranslation(showTranslation === index ? -1 : index)}
                      className="flex items-center justify-center self-center p-1.5 rounded-full text-white bg-blue-500 hover:bg-blue-600 disabled:text-slate-100 disabled:bg-slate-300"
                    >
                      <LanguageIcon className={classNames("w-[17px] h-[17px] !stroke-[2.5]")} />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => setShowTranslation(showTranslation === index ? -1 : index)}
                      className="flex items-center justify-center self-center p-1.5 rounded-full bg-red-500 hover:bg-red-600"
                    >
                      <XMarkIcon
                        className={classNames("w-[16px] h-[16px] text-white !stroke-[2.5]", {
                          "hidden group-hover/line:inline": showTranslation !== index,
                          inline: showTranslation === index,
                        })}
                      />
                    </button>
                  </>
                )}
                {speechLineIndex !== index || playLine === -1 ? (
                  <button
                    disabled={
                      (speechLineIndex < lines.length && speechLineIndex !== index) ||
                      (speechLineIndex < lines.length && playLine === -1) ||
                      showTranslation === index
                    }
                    onClick={() => (speechLineIndex > lines.length - 1 ? triggerLine(index) : undefined)}
                    className="flex items-center justify-center self-center p-1.5 rounded-full text-white bg-blue-500 hover:bg-blue-600 disabled:bg-slate-300 disabled:text-slate-100"
                  >
                    <PlayIcon className="w-4 h-4" />
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setPlayLine(-1);
                      setSpeechLineIndex(Number.MAX_VALUE);
                    }}
                    className="flex items-center justify-center self-center p-1.5 rounded-full bg-red-500 hover:bg-red-600"
                  >
                    <ForwardIcon className="w-4 h-4 text-white" />
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
        <div className="flex my-1 gap-2 justify-center pb-2 pt-4">
          {speechLineIndex < lines.length && playLine === -1 ? (
            <button
              onClick={() => {
                setSpeechLineIndex(Number.MAX_VALUE);
                setShowTranslation(-1);
              }}
              className="flex items-center gap-2 justify-center self-center px-3 rounded-full text-white bg-red-500 hover:bg-red-600"
            >
              <ForwardIcon className="w-4 h-4 cursor-pointer" /> Skip dialogue
            </button>
          ) : (
            <button
              disabled={playLine !== -1}
              onClick={() => {
                setSpeechLineIndex(0);
                setPlayLine(-1);
                setShowTranslation(-1);
              }}
              className="flex items-center gap-2 justify-center self-center px-3 rounded-full text-white bg-blue-500 hover:bg-blue-600 disabled:bg-slate-300 disabled:text-slate-100"
            >
              <PlayIcon className="w-4 h-4 cursor-pointer" /> Play dialogue
            </button>
          )}
        </div>
      </div>
    </SpeechSynthProvider>
  );
};

export default DialogueMedium;
