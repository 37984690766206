/* eslint-disable @typescript-eslint/no-unused-vars */
import { ForwardIcon, PlayIcon } from "@heroicons/react/24/solid";
import { LanguageIcon, XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

import { useExercise } from "components/Lesson/Exercise";
import { useLesson } from "components/Lesson/LessonContext";
import Medium from "components/Lesson/Medium";
import { ReadText } from "components/Text";
import { SpeechSynthContext, SpeechSynthProvider } from "components/useSpeechSynth";
import { useAPI, useAPIStream } from "services/api";
import { EvaluateConversationData, EvaluateDiscussionData } from "services/api/routes";
import { OpenAIChatMessage } from "types";
import { CompleteExerciseButton, ScrollChat } from "./";
import ChatBubble, { Hint, Proofread } from "./ChatBubble";
import { InputAndControls } from "./InputAndControls";
import { TextInput } from "./Inputs";
import TranslateLine from "./TranslateLine";
import twm from "utils/twm";

type Message = OpenAIChatMessage & { className?: string; speakerID?: string; plain?: boolean };

type DialogueExerciseProps = {
  defaultSpeakerID: string;
  evaluateService: (arg0: EvaluateConversationData | EvaluateDiscussionData) => any;
  initialMessages: Message[];
  processMessage: (message: Message) => Message;
};

type AssistantBubbleProps = {
  className?: string;
  content: string;
  disablePlay?: boolean;
  disableTranslate?: boolean;
  speakerID?: string;
  hint?: string;
  header?: string;
  speechSynthID?: string;
  plain?: boolean;
};

export const AssistantBubble = ({
  className = "",
  content,
  disablePlay = false,
  disableTranslate = false,
  hint,
  speakerID,
  header,
  speechSynthID,
  plain,
}: AssistantBubbleProps) => {
  const [play, setPlay] = useState(false);
  const [translate, setTranslate] = useState(false);

  const { activeID, onComplete: onSpeechSynthComplete } = React.useContext(SpeechSynthContext);

  useEffect(() => {
    setPlay(activeID === speechSynthID);
  }, [activeID, speechSynthID]);

  const handleSpeechSynthComplete = () => {
    setPlay(false);

    if (activeID !== speechSynthID) return; // Play was triggered by this component.
    if (!onSpeechSynthComplete || !speechSynthID) return;

    onSpeechSynthComplete({ speechSynthID });
  };

  return (
    <SpeechSynthProvider activeID={play ? "message" : null} onComplete={handleSpeechSynthComplete}>
      <ChatBubble className={twMerge("bg-slate-50 w-full", className)}>
        {!!header && !plain && <div className="font-semibold leading-5 pt-2">{header}</div>}{" "}
        <div className="flex relative">
          <div className={classNames("grow mr-4", { hidden: !translate })}>
            {translate && <TranslateLine phrase={content} />}
          </div>
          <ReadText
            speechSynthID="message"
            speakerID={speakerID}
            text={content}
            className={classNames("grow mr-4", { hidden: translate })}
            displayAll={!play}
            plainText={plain ?? false}
          />
          <div className="flex flex-row gap-2 items-start mt-2">
            {content && !disableTranslate && (
              <>
                {translate ? (
                  <button
                    onClick={() => setTranslate(false)}
                    className="flex items-center justify-center p-1.5 rounded-full bg-red-500 hover:bg-red-600"
                  >
                    <XMarkIcon className="w-[17px] h-[17px] text-white cursor-pointer inline-block !stroke-[2.5] shrink-0" />
                  </button>
                ) : (
                  <button
                    onClick={() => setTranslate(true)}
                    className="flex items-center justify-center p-1.5 rounded-full bg-blue-500 hover:bg-blue-600"
                  >
                    <LanguageIcon className="w-[17px] h-[17px] inline-block cursor-pointer !stroke-[2.5] text-white shrink-0" />
                  </button>
                )}
              </>
            )}
            {content && !disablePlay && (
              <>
                {play ? (
                  <button
                    onClick={() => setPlay(false)}
                    className="flex items-center justify-center p-1.5 rounded-full bg-red-500 hover:bg-red-600"
                  >
                    <ForwardIcon className={classNames("w-4 h-4 inline-block cursor-pointer text-white shrink-0")} />
                  </button>
                ) : (
                  <button
                    onClick={() => setPlay(true)}
                    className="flex items-center justify-center p-1.5 rounded-full bg-blue-500 hover:bg-blue-600"
                  >
                    <PlayIcon className={classNames("w-4 h-4 inline-block cursor-pointer text-white shrink-0")} />
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        {hint && (
          <div className="rounded-md leading-normal">
            <Hint className="leading-normal float-right" hint={hint} />
          </div>
        )}
      </ChatBubble>
    </SpeechSynthProvider>
  );
};

const DialogueExercise = function DialogueExerciseComponent({
  defaultSpeakerID,
  evaluateService,
  initialMessages,
  processMessage,
}: DialogueExerciseProps) {
  const exercise = useExercise();
  const { t } = useTranslation();
  const { services } = useAPI();
  const { stream, streamData } = useAPIStream();
  const { onPreComplete, vocabularyView } = useLesson();

  const [complete, setComplete] = useState(false);
  const [inputEnabled, setInputEnabled] = useState(true);
  const [messages, setMessages] = useState<Message[]>(initialMessages);
  const [speechSynthID, setSpeechSynthID] = useState<string | null>(null);
  const [expand, setExpand] = useState(false);

  const expandOnFocus = useCallback((state: boolean) => setExpand(state), [setExpand]);

  useEffect(() => {
    setComplete(false);
    setInputEnabled(true);
    setMessages(initialMessages);
    setSpeechSynthID(null);
  }, [exercise.id]);

  const handleSubmit = useCallback(
    (text: string) => {
      if (!text) return;

      const updatedMessages = [
        ...messages,
        { content: text, role: "user" },
        { content: "", role: "assistant" },
      ] as OpenAIChatMessage[];

      setMessages(updatedMessages);
      setInputEnabled(false);

      (async () => {
        const { text: content, continue: cont } = await evaluateService({
          exerciseID: exercise.id,
          messages: updatedMessages.slice(initialMessages.length, -1),
          stream,
        });

        setMessages((prev) => [...prev.slice(0, -1), { content, role: "assistant" }]);

        if (cont) {
          setInputEnabled(true);
          return;
        }

        onPreComplete();
        setComplete(true);
      })();
    },
    [exercise.id, messages, onPreComplete, services, setComplete, setMessages, setSpeechSynthID, stream],
  );

  // const focusOnRender = useCallback((element: HTMLButtonElement | HTMLTextAreaElement | null) => {
  //   if (element === null) return;
  //
  //   element.focus();
  // }, []);

  useEffect(() => {
    if (!streamData) return;

    setMessages((prev) => [...prev.slice(0, -1), { content: streamData?.text ?? "", role: "assistant" }]);
  }, [streamData]);

  const processedMessages = useMemo(() => messages.map((m) => processMessage(m)), [messages, processMessage]);

  return (
    <SpeechSynthProvider activeID={speechSynthID}>
      <ScrollChat className={classNames({ hidden: vocabularyView })}>
        <div className="h-1"></div>
        {exercise.directions && (
          <AssistantBubble
            content={exercise.directions}
            header={t("lesson.exercise.directions")}
            className="bg-blue-100 w-full"
            disablePlay={true}
            plain={exercise.data?.plainDescription}
            disableTranslate={exercise.data?.plainDescription}
          ></AssistantBubble>
        )}
        <ChatBubble className="bg-slate-50 w-full">
          <Medium speechSynthID="medium" {...exercise.medium} />
        </ChatBubble>
        {processedMessages.map(({ className, content, role, speakerID }, index) =>
          role === "assistant" ? (
            <AssistantBubble
              content={content}
              key={index}
              hint={index === 0 ? exercise.data.hint : undefined}
              speakerID={speakerID ?? defaultSpeakerID}
              speechSynthID={`message-${index}`}
              plain={exercise.data?.plain && index === 0}
              disablePlay={exercise.data?.plain && index === 0}
              disableTranslate={exercise.data?.plain && index === 0}
              className={twm(className, { "font-bold bg-slate-100": exercise.data?.plain && index === 0 })}
            />
          ) : (
            <ChatBubble className={twMerge("group bg-green-100 self-end", className)} key={index}>
              <div className="leading-relaxed">
                <div>{content}</div>
                <Proofread className="float-right" text={content} />
              </div>
            </ChatBubble>
          ),
        )}
        {complete && <CompleteExerciseButton />}
        <div className="">&nbsp;</div>
      </ScrollChat>
      <InputAndControls disabled={!inputEnabled || complete} expand={expand}>
        <TextInput
          disabled={!inputEnabled || complete || vocabularyView}
          expectingInput={inputEnabled && !complete && !vocabularyView}
          placeholder={t("lesson.exercise.speakOrType")}
          placeholderSpeak={t("lesson.exercise.startSpeaking")}
          onSubmit={inputEnabled && !complete ? handleSubmit : undefined}
          onFocus={expandOnFocus}
        />
      </InputAndControls>
    </SpeechSynthProvider>
  );
};

export default DialogueExercise;
