import React, { useState } from "react";
import { SpeakerWaveIcon, SpeakerXMarkIcon } from "@heroicons/react/24/solid";
import { LanguageIcon, XMarkIcon, BookOpenIcon } from "@heroicons/react/24/outline";
import { useLesson } from "components/Lesson/LessonContext";
import classNames from "classnames";
import { TranslateInput } from "./Inputs";
import twm from "utils/twm";

interface InputAndControlsProps {
  children?: React.ReactNode;
  disabled?: boolean;
  expand?: boolean;
  vocabularyScreen?: boolean;
}

export const InputAndControls: React.FC<InputAndControlsProps> = function InputAndControlsComponent({
  children,
  disabled = false,
  expand = false,
  vocabularyScreen = true,
}) {
  const { vocabularyView, toggleVocabularyView } = useLesson();
  const { audio, toggleAudio } = useLesson();
  const [translate, setTranslate] = useState(false);
  const [blockHover, setBlockHover] = useState(false);

  return (
    <>
      <div className={classNames("flex relative box-border p-3 gap-4 border-t border-slate-100", {})}>
        <div
          className={classNames(
            "flex grow",
            { "opacity-50": disabled && !translate && !vocabularyView },
            { "opacity-100": (!disabled || translate) && !vocabularyView },
            { "opacity-0": vocabularyView },
          )}
        >
          {translate ? <TranslateInput /> : children}
        </div>
        <div className={twm("pr-1.5 py-1 mb-px flex items-center gap-2", { "sm:flex hidden": expand })}>
          {!vocabularyView && (
            <>
              {translate ? (
                <XMarkIcon
                  className={classNames(
                    "w-6 h-6 cursor-pointer -mb-0.5 hover:text-red-600 stroke-[2.5px] text-slate-400",
                    {},
                  )}
                  onClick={() => setTranslate((prev) => !prev)}
                />
              ) : (
                <LanguageIcon
                  className={classNames("w-6 h-6 -mb-0.5 cursor-pointer hover:text-green-600 stroke-2", {
                    "text-blue-500": translate,
                    "text-slate-400": !translate,
                  })}
                  onClick={() => setTranslate((prev) => !prev)}
                />
              )}
              {!translate && (
                <>
                  {!audio ? (
                    <span
                      className="group"
                      onClick={() => {
                        setBlockHover(true);
                        toggleAudio();
                      }}
                      onMouseLeave={() => {
                        setBlockHover(false);
                      }}
                    >
                      <SpeakerXMarkIcon
                        className={classNames("w-6 h-6 text-slate-400 cursor-pointer", {
                          "inline-block group-hover:hidden hover:text-green-600 ": !blockHover,
                          "inline-block hover:text-red-700": blockHover,
                        })}
                      />
                      <SpeakerWaveIcon
                        className={classNames("w-6 h-6 text-slate-400 cursor-pointer", {
                          "hidden group-hover:inline-block hover:text-green-600": !blockHover,
                          hidden: blockHover,
                        })}
                      />
                    </span>
                  ) : (
                    <span
                      className="group"
                      onClick={() => {
                        setBlockHover(true);
                        toggleAudio();
                      }}
                      onMouseLeave={() => {
                        setBlockHover(false);
                      }}
                    >
                      <SpeakerWaveIcon
                        className={classNames("w-6 h-6 text-slate-400 cursor-pointer", {
                          "inline-block hover:text-green-700": blockHover,
                          "inline-block group-hover:hidden hover:text-red-600": !blockHover,
                        })}
                      />
                      <SpeakerXMarkIcon
                        className={classNames("w-6 h-6 text-slate-400 cursor-pointer", {
                          "hidden group-hover:inline-block hover:text-red-600": !blockHover,
                          hidden: blockHover,
                        })}
                      />
                    </span>
                  )}
                </>
              )}
            </>
          )}
          {vocabularyScreen && !translate && (
            <div className="h-10 flex items-center py-1">
              <div
                className="self-center shadow hover:shadow-lg mt-0.5 ml-2 border-2 cursor-pointer border-slate-600 rounded-sm p-0.5 text-slate-700 bg-white"
                onClick={() => toggleVocabularyView()}
              >
                {vocabularyView ? (
                  <XMarkIcon className="w-5 h-5 stroke-2" />
                ) : (
                  <BookOpenIcon className="w-4 h-4 stroke-2 m-0.5" />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
