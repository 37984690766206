import { PlayIcon } from "@heroicons/react/24/solid";
import { ChartBarIcon, RectangleStackIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { ChatBubble } from "components/Lesson/Exercise/shared";
import SearchInput from "components/SearchInput";
import { useCourseContext } from "components/UserInterface/CourseContext";
import Navigation from "components/UserInterface/Navigation";
import { useAPI } from "services/api";
import { Lesson, LessonRecommendation } from "services/api/types";
import Loader from "components/Loader";
import useDebounce from "utils/useDebounce";
import handleError from "utils/handleError";
import usePagination from "utils/usePagination";
import twm from "utils/twm";
import { StatReturn } from "services/api/routes";

type LessonPreviewProps = { className?: string; lesson?: Lesson };

const LessonPreview: React.FC<LessonPreviewProps> = function LessonPreviewComponent({ className, lesson }) {
  const { t } = useTranslation();

  return (
    <div className={twMerge("p-4 bg-slate-50 rounded-md shadow-md text-black font-tutor", className)}>
      <div className="flex justify-between items-stretch gap-4">
        <h2 className={classNames("text-xl flex-grow font-semibold", { "bg-slate-300": !lesson })}>
          {lesson?.title ?? "\u00A0"}
        </h2>
        <div
          className={classNames("flex gap-2 items-center cursor-pointer px-2 font-sans font-semibold", {
            "bg-slate-300": !lesson,
          })}
          title={t("course.preview.levelTitle", { level: lesson?.level.toUpperCase() })}
        >
          <ChartBarIcon className="w-4 h-4" /> {lesson?.level.toUpperCase() ?? "\u00A0\u00A0"}
        </div>
      </div>
      <div className="flex mt-4">
        <div className={classNames("grow", { "bg-slate-300": !lesson })}>
          {lesson?.description ?? (
            <>
              <div>{"\u00A0"}</div>
              <div>{"\u00A0"}</div>
            </>
          )}
        </div>
        <div className="flex items-end mt-12 shrink-0">
          {lesson && (
            <Link
              className="flex items-center gap-2 px-5 py-2 bg-green-500 hover:bg-green-600 rounded text-white"
              to={`/lesson/${lesson.id}`}
              title={t("course.preview.startTitle")}
            >
              <PlayIcon className="w-4 h-4 inline" /> {t("course.preview.start")}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

const NextLesson = function NextLessonComponent() {
  const [lesson, setLesson] = useState<LessonRecommendation | null>(null);
  const [shouldSignUp, setShouldSignUp] = useState<boolean>(false);
  const [vocab, setVocab] = useState<boolean>(false);
  const [activeUserData, setActiveUserData] = useState<StatReturn | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { t } = useTranslation();
  const { language, level, instruction } = useCourseContext();
  const { services } = useAPI();

  useEffect(() => {
    if (!language || !level || !instruction) return;

    setIsLoading(true);

    (async () => {
      setIsLoading(true);

      let result;
      try {
        result = await services.suggestLessons({ language, level, instruction });
      } catch (error) {
        handleError(error);
        return;
      }

      const lowestWeight = Math.min(...result.lessons.map(({ weight }: LessonRecommendation) => weight));
      const selectableLessons = result.lessons.filter(({ weight }: LessonRecommendation) => weight === lowestWeight);
      const selectedLesson = selectableLessons[Math.floor(Math.random() * selectableLessons.length)];

      setIsLoading(false);
      setLesson(selectedLesson);
      setShouldSignUp(result.shouldSignUp);
      setVocab(result.vocab);

      try {
        result = await services.queryStat({});
        setActiveUserData({ activeUserCount: result.activeUserCount, activeUsers: result.activeUsers });
      } catch (error) {
        /* Ignore */
      }
    })();
  }, [language, level, instruction]);

  const lessonCompleted = useMemo(() => lesson?.solvedExerciseCount === lesson?.exerciseCount, [lesson]);
  const lessonStarted = useMemo(() => lesson && lesson.solvedExerciseCount > 0, [lesson]);

  return (
    <div className="flex flex-col justify-between gap-8 text-slate-900 px-6 mt-8">
      {Boolean(activeUserData?.activeUserCount) && (
        <div className="flex flex-wrap text-xs text-slate-400 whitespace-nowrap justify-start">
          <div className="font-semibold mr-1">{t("course.next.activeUsers")}</div>
          {activeUserData!.activeUsers.map(({ id, name }) => (
            <>
              <Link className="flex flex-row gap-1 hover:underline" to={`/profile/${id}`} key={id}>
                {name}
              </Link>
              <div className="mr-1">,</div>
            </>
          ))}
          <div>
            {t("course.next.activeUsersMore", {
              count: activeUserData!.activeUserCount - activeUserData!.activeUsers.length,
            })}
          </div>
        </div>
      )}
      {vocab && (
        <ChatBubble
          className={classNames(
            "w-full p-6 font-tutor leading-loose flex flex-row justify-between items-center gap-5",
            {
              "bg-green-100 shadow-sm": !isLoading,
              "bg-white": isLoading,
            }
          )}
        >
          <div className="flex gap-4 items-center">
            <RectangleStackIcon className="w-8 h-8 inline-block" />
            <div className="text-xl font-bold">{t("course.next.vocabReview")}</div>
          </div>
          <Link
            to="/lesson/vocab"
            className="z-10 block bg-green-500 hover:bg-green-600 rounded-sm text-center leading-loose text-white py-2 px-5 shrink-0 text-lg"
          >
            {t("course.next.startVocabReview")}
          </Link>
        </ChatBubble>
      )}
      <ChatBubble
        className={classNames("flex w-full p-6 font-tutor leading-loose flex-col gap-10", {
          "bg-slate-50 shadow-sm": !isLoading,
          "bg-white": isLoading,
          "sm:flex-col-reverse": vocab,
        })}
      >
        {lesson && (
          <>
            {!vocab && (
              <h1 className="text-2xl">
                <span className="font-semibold ">{t("course.next.title")}: </span>
              </h1>
            )}
            <div className="grow flex items-center">
              <div className="bg-white shadow-md -mt-3 rounded-md p-4 grow relative">
                <h2 className={twm("text-xl w-9/12", { "text-lg": vocab })}>
                  <span className="font-semibold">{lesson?.title}</span>
                </h2>
                <div className="text-md mt-2">{lesson?.description}</div>
                <div className="absolute top-4 right-4 font-sans font-bold align-baseline leading-normal">
                  <ChartBarIcon className="w-3.5 h-3.5 inline-block mb-0.5" /> {lesson?.level.toUpperCase()}
                </div>
              </div>
            </div>
            <div className={twm("flex flex-col gap-4 sm:flex-row grow justify-between")}>
              {vocab && (
                <h1 className="hidden sm:block text-lg">
                  <span className="font-semibold ">{t("course.next.alternativeTitle")}: </span>
                </h1>
              )}
              <Link
                to={shouldSignUp ? `/signup-to-continue` : `/lesson/${lesson?.id}${lessonCompleted ? "/summary" : ""}`}
                className={twm(
                  "grow block bg-green-500 hover:bg-green-600 rounded-sm text-center text-white py-1 px-4 sm:py-2 sm:px-8 shrink-0 text-lg",
                  {
                    "bg-blue-500 hover:bg-blue-600": lessonCompleted || lessonStarted,
                    "bg-gray-400 hover:bg-gray-500 text-md grow-0": vocab,
                  },
                  "leading-loose"
                )}
              >
                {!vocab && !lessonStarted && !lessonCompleted && t("course.next.startLesson")}
                {!vocab && lessonStarted && !lessonCompleted && t("course.next.continueLesson")}
                {!vocab && lessonCompleted && t("course.next.showSummary")}
                {vocab && t("course.next.skipVocab")}
              </Link>
            </div>
          </>
        )}
        {lesson === null && !isLoading && (
          <h1 className="text-2xl self-center">
            <span className="font-semibold ">{t("course.next.noLesson", { level: level?.toUpperCase() })}</span>
          </h1>
        )}
        {isLoading && (
          <div className="h-56 flex items-center justify-center">
            <Loader />
          </div>
        )}
      </ChatBubble>
    </div>
  );
};

const LessonSearch: React.FC<{ onChange?: (value: boolean) => void }> = function LessonSearchComponent({ onChange }) {
  const [query, setQuery] = useState<string>("");
  const ref = useRef<HTMLInputElement | null>(null);

  const { debounce, isDebouncing } = useDebounce({ delay: 700 });
  const { t } = useTranslation();
  const { services } = useAPI();
  const { language, level, instruction } = useCourseContext();

  const fetchLessons = async ({ limit, offset }: { limit: number; offset: number }) => {
    let lessons;

    try {
      lessons = (await services.searchLessons({ instruction, language, limit, offset, query })).lessons;
    } catch (error) {
      handleError(error);
      return [];
    }

    return lessons;
  };

  const {
    canLoadMore,
    clear,
    data: lessons,
    isLoading,
    loadMore,
    reset,
  } = usePagination<Lesson>({ load: fetchLessons, loadInitially: false });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setQuery(event.target.value);

    if (!value) {
      clear();
      return;
    }

    debounce(reset);
  };

  useEffect(() => {
    if (onChange) onChange(Boolean(query) || document.activeElement === ref.current);
  }, [query, onChange]);

  // Sort lessons so that first, lessons with lesson.level === level are shown and keep the order of the rest
  const sortedLessons = lessons.slice().sort((a, b) => {
    if (a.level === level) return -1;
    if (b.level === level) return 1;
    return 0;
  });

  return (
    <div className="flex flex-grow py-4 pb-0 sm:py-8 sm:px-8">
      <div
        className={classNames("flex flex-grow flex-col justify-end focus-within:justify-start", {
          "justify-start": query,
        })}
      >
        <div className="space-y-4 px-4 pb-4">
          <SearchInput
            autoComplete="off"
            autoCorrect="off"
            placeholder={t("course.search.placeholder")}
            onBlur={() => onChange && onChange(Boolean(query))}
            onFocus={() => onChange && onChange(true)}
            onChange={handleSearchChange}
            ref={ref}
            type="text"
            value={query}
          />
        </div>
        <div
          className={classNames("flex basis-0 flex-col space-y-4 p-4 pt-0 overflow-y-auto", {
            "flex-grow": query,
          })}
        >
          {query && sortedLessons.map((lesson, index) => <LessonPreview key={index} lesson={lesson} />)}
          {!isDebouncing && !isLoading && !lessons.length && query && (
            <span className="italic text-slate-500">{t("course.search.noLesson")}</span>
          )}
          {isDebouncing || isLoading ? (
            <div className="flex flex-col gap-4">
              <LessonPreview className="animate-pulse" />
              <LessonPreview className="animate-pulse" />
              <LessonPreview className="animate-pulse" />
            </div>
          ) : null}
          <div className="flex">
            {canLoadMore && (
              <button
                className="flex-grow bg-blue-500 hover:bg-blue-600 transition-colors text-white py-2 rounded-md shadow-md"
                onClick={loadMore}
              >
                {t("course.search.loadMore")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Course: React.FC = function CoursePageComponent() {
  const { language, level, instruction } = useCourseContext();
  const navigate = useNavigate();
  const [hide, setHide] = useState<boolean>(false);

  useEffect(() => {
    if (language && level && instruction) return;

    navigate("/setup");
  }, [language, level, instruction]);

  return (
    <div className="flex flex-col flex-grow">
      <Navigation />
      <div className={twm({ hidden: hide })}>
        <NextLesson />
      </div>
      <LessonSearch onChange={setHide} />
    </div>
  );
};

export default Course;
