import moment from "moment";
import { ChevronLeftIcon, ChevronRightIcon, ClockIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import SearchInput from "components/SearchInput";
import { useCourseContext } from "components/UserInterface/CourseContext";
import { useAPI } from "services/api";
import { ListVocabularyCardsResult } from "services/api/routes";
import { VocabularyCard } from "services/api/types";
import { useNavigate } from "react-router-dom";
import Navigation from "../Navigation";

interface VocabularyCardProps {
  card: VocabularyCard;
  onChange: () => void;
  onSelect: (card: VocabularyCard) => void;
}

const VocabCard = ({ card, onChange, onSelect }: VocabularyCardProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    onChange();
  }, []);

  const timeDifference = useMemo(() => {
    const now = moment();
    const dueDate = moment(card.dueAt);
    const duration = moment.duration(dueDate.diff(now));

    if (duration.asMinutes() < 0) return ["vocab.dueIn.now"];
    if (duration.asYears() >= 1) return ["vocab.dueIn.years", { count: Math.ceil(duration.asYears()) }];
    if (duration.asMonths() >= 1) return ["vocab.dueIn.months", { count: Math.ceil(duration.asMonths()) }];
    if (duration.asWeeks() >= 1) return ["vocab.dueIn.weeks", { count: Math.ceil(duration.asWeeks()) }];
    if (duration.asDays() >= 1) return ["vocab.dueIn.days", { count: Math.ceil(duration.asDays()) }];
    if (duration.asHours() >= 1) return ["vocab.dueIn.hours", { count: Math.ceil(duration.asHours()) }];

    return ["minutes", { count: Math.ceil(duration.asMinutes()) }];
  }, [card]);

  return (
    <div
      className="flex flex-col gap-2 px-4 py-2 border-b border-b-slate-200 last:border-b-0 hover:bg-slate-100 transition-colors cursor-pointer"
      onClick={() => onSelect(card)}
    >
      <div className="flex flex-col">
        <div className="text-lg font-semibold">{card.text}</div>
        <div className="flex flex-row gap-4 justify-between">
          <div className="">{card.translation}</div>
          <div className="flex flex-row gap-1 text-xs text-slate-600 items-center">
            <ClockIcon className="w-4 h-4" />
            {t(...timeDifference)}
          </div>
        </div>
      </div>
    </div>
  );
};

const limit = 25;

const VocabStack = function VocabStackComponent() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language, instruction } = useCourseContext();
  const { services } = useAPI();
  const [offset, setOffset] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [stack, setStack] = useState<ListVocabularyCardsResult["vocabularyCards"]>([]);
  const [query, setQuery] = useState<string>("");

  const fetchStack = useCallback(async () => {
    let result;
    try {
      result = await services.listVocabularyCards({ instruction, language, limit, offset, query });
    } catch (error) {
      return;
    }

    setStack(result.vocabularyCards);
    setCount(result.count);
  }, [instruction, language, limit, offset, query, services, setCount, setStack]);

  useEffect(() => {
    fetchStack();
  }, [limit, offset, query]);

  return (
    <div className="flex flex-col grow overflow-y-hidden">
      <Navigation />
      <div className="flex flex-col px-4 py-4">
        <SearchInput
          type="text"
          placeholder={t("vocab.searchPlaceholder")}
          onChange={(e) => setQuery(e.target.value)}
          value={query}
        />
      </div>
      <div className="flex flex-col grow border-slate-400 overflow-y-auto">
        {stack.length > 0 ? (
          stack.map((card) => (
            <VocabCard
              key={card.id}
              card={card}
              onChange={fetchStack}
              onSelect={() => navigate(`/vocab/edit/${card.id}`)}
            />
          ))
        ) : (
          <div className="text-xl font-bold text-center px-4 py-16">{t("vocab.noCardsInStack")}</div>
        )}
      </div>
      <div className="flex flex-row justify-between">
        <div className="grow" />
        <div className="flex flex-row justify-center items-center py-2 gap-2">
          <button
            className="px-1 py-2 border rounded hover:bg-blue-500 hover:text-white hover:border-transparent disabled:bg-slate-200 disabled:text-slate-500 disabled:border-transparent"
            onClick={() => setOffset(offset - limit)}
            disabled={offset === 0}
          >
            <ChevronLeftIcon className="w-4 h-4" />
          </button>
          <div className="text-slate-500 px-2 text-sm">
            {offset + 1} - {Math.min(count, (offset + 1) * limit)} of {count}
          </div>
          <button
            className="px-1 py-2 border rounded hover:bg-blue-500 hover:text-white hover:border-transparent disabled:bg-slate-200 disabled:text-slate-500 disabled:border-transparent"
            onClick={() => setOffset(offset + limit)}
            disabled={count <= (offset + 1) * limit}
          >
            <ChevronRightIcon className="w-4 h-4" />
          </button>
        </div>
        <div className="flex flex-row grow justify-end text-black hover:text-blue-600 transition-colors">
          <button onClick={() => navigate("/vocab/edit")} className="px-4 py-2 ">
            <PlusIcon className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default VocabStack;
