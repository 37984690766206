import React, { useState, useCallback } from "react";
import { AssistantBubble } from "./shared/DialogueExercise";
import { InputAndControls } from "./shared/InputAndControls";
import { useAPI } from "services/api";
import handleError from "utils/handleError";
import { useExercise } from ".";
import { useLesson } from "../LessonContext";
import { useTranslation } from "react-i18next";
import { ChatBubble, CompleteExerciseButton, ScrollChat } from "./shared";
import twm from "utils/twm";
import Medium from "components/Lesson/Medium";
import { CompleteExerciseButtonButton } from "./shared/CompleteExerciseButton";
import { resetBlankIndex } from "utils/pretreatText";

const InputAnswer: React.FC<{
  index: number;
  updateAnswer: (index: number, index2: number, value: string) => void;
  hightlightEmpty: boolean;
  answer: string[];
  complete: boolean;
  userAnswers: string[][];
}> = function InputAnswerComponent({ index, updateAnswer, complete, answer, hightlightEmpty, userAnswers }) {
  const { t } = useTranslation();
  return answer.map((exerciseAnswer, answerIndex) => {
    const correct = userAnswers?.[index]?.[answerIndex]?.toUpperCase().trim() === exerciseAnswer.toUpperCase().trim();
    return (
      <div
        className={twm("col-span-3 py-1 border-b-2 border-gray-300", {
          "border-red-600": hightlightEmpty && !userAnswers?.[index]?.[answerIndex],
          "col-span-2": answer.length > 1,
        })}
      >
        {!complete && (
          <input
            type="text"
            className={twm("bg-transparent outline-none", {
              "placeholder-red-400": hightlightEmpty && !userAnswers?.[index]?.[answerIndex],
            })}
            placeholder={
              answerIndex === 0 &&
              (answer.length > 1
                ? t("lesson.exercise.chatBubble.fillIn.placeholderShort")
                : t("lesson.exercise.chatBubble.fillIn.placeholder"))
            }
            onChange={(e) => updateAnswer(index, answerIndex, e.target.value)}
          />
        )}
        {complete && (
          <div
            className={twm({
              "line-through italic text-red-600": !correct,
              "text-green-600 font-bold": correct,
            })}
          >
            {userAnswers?.[index]?.[answerIndex]}
            {correct ? " ✓" : ""}
          </div>
        )}
      </div>
    );
  });
};

const FillInExercise: React.FC = function FillInExerciseComponent() {
  const exercise = useExercise();
  const { services } = useAPI();
  const { vocabularyView, onPreComplete } = useLesson();
  const [answers, setAnswers] = useState<string[][]>([]);
  const [complete, setComplete] = useState(false);
  const [hightlightEmpty, setHightlightEmpty] = useState(false);
  resetBlankIndex();
  const { t } = useTranslation();
  const updateAnswer = useCallback(
    (index: number, index2: number, value: string) => {
      if (hightlightEmpty) setHightlightEmpty(false);
      let newAnswers = [...answers];
      if (answers.length === 0) {
        newAnswers = [];
        exercise.data.answers.forEach((a: string[], _: number) => {
          newAnswers = [...newAnswers, Array(a.length).fill("")];
        });
      }
      newAnswers[index][index2] = value;
      setAnswers(newAnswers);
    },
    [answers, exercise.data.answers, hightlightEmpty],
  );

  const submitAnswers = useCallback(() => {
    const completed = !!answers?.length && answers.every((answer) => answer.every((a) => a.trim().length > 0));
    setHightlightEmpty(!completed);
    if (completed) {
      services
        .completeExercise({ exerciseID: exercise.id })
        .then(() => {
          onPreComplete();
          setComplete(true);
        })
        .catch((error: any) => {
          handleError(error);
        });
    }
  }, [answers, services, exercise]);

  return (
    <>
      <ScrollChat className={twm({ hidden: vocabularyView })}>
        <div className="h-1"></div>
        {exercise.directions && (
          <AssistantBubble
            content={exercise.directions}
            header={t("lesson.exercise.directions")}
            className="bg-blue-100 w-full"
            disablePlay={true}
            plain={exercise.data?.plainDescription}
            disableTranslate={exercise.data?.plainDescription}
          ></AssistantBubble>
        )}
        <ChatBubble className="bg-slate-50 w-full">
          <Medium speechSynthID="medium" {...exercise.medium} />
        </ChatBubble>
        <AssistantBubble
          content={exercise.data.text}
          plain={exercise.data?.plain}
          speakerID={exercise.data.speakerID}
          hint={exercise.data?.hint}
          disableTranslate={exercise.data?.plain}
          disablePlay={exercise.data?.plain}
          className={twm({ "font-bold bg-slate-100": exercise.data?.plain })}
        />
        <ChatBubble className="bg-green-100 w-full">
          <h1 className="font-bold">{t("lesson.exercise.chatBubble.fillIn.title")}</h1>
          <ul className="flex flex-col w-full items-stretch">
            {exercise.data.answers.map((answer: string[], index: number) => {
              return (
                <li key={index} className="p-2 grid grid-cols-11 gap-x-2">
                  <div className="py-1">{index + 1}.</div>
                  <InputAnswer
                    index={index}
                    updateAnswer={updateAnswer}
                    hightlightEmpty={hightlightEmpty}
                    answer={answer}
                    complete={complete}
                    userAnswers={answers}
                  />
                  <div
                    className={twm("col-span-7", {
                      "col-span-6": answer.length === 2,
                      "col-span-4": answer.length === 3,
                    })}
                  >
                    {complete && (
                      <div className="py-1 align-baseline">
                        {t("lesson.exercise.chatBubble.fillIn.correctAnswer")}:{" "}
                        <b>{exercise.data.answers[index].join(" ")}</b>
                      </div>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </ChatBubble>
        {!complete && (
          <CompleteExerciseButtonButton
            continueText={t("lesson.exercise.chatBubble.fillIn.submit")}
            handleClick={submitAnswers}
            isLastExercise={false}
            className="bg-blue-600 hover:bg-blue-700"
          ></CompleteExerciseButtonButton>
        )}
        {complete && <CompleteExerciseButton />}
        <div className="h-10">&nbsp;</div>
      </ScrollChat>
      <InputAndControls />
    </>
  );
};

export default FillInExercise;
