import { twMerge } from "tailwind-merge";

const InputLabel = function InputLabelComponent({
  className,
  label,
  ...props
}: {
  className?: string;
  label?: string;
  [key: string]: any;
}) {
  if (!label) return null;

  return (
    <div className={twMerge("text-black text-md", className)} {...props}>
      {label}
    </div>
  );
};

export default InputLabel;
