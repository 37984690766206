import { ReadText } from "components/Text";
import React from "react";

type TableProps = {
  content: { rows: string[][]; translations?: string[][] };
};

const Table: React.FC<TableProps> = function TableComponent({ content: { rows } }) {
  return (
    <div className="inline-block py-6">
      <table className="table shadow border border-slate-200">
        <thead>
          <tr className="table-row bg-slate-100">
            {rows[0].map((item: string, idx: number) => (
              <td key={idx} className="table-cell px-4 py-2">
                {item === "00" ? <>&nbsp;</> : <ReadText text={item} displayAll={true} />}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.slice(1).map((row: string[], ridx: number) => (
            <tr key={ridx} className="table-row even:bg-slate-100">
              {row.map((item: string, idx: number) => (
                <td key={idx} className="table-cell px-4 py-1">
                  {item === "00" ? <>&nbsp;</> : <ReadText text={item} displayAll={true} />}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
