import React, { useMemo } from "react";

import { useTranslations } from "services/api/TranslationContext";
import suspensify, { TriggerSuspense } from "utils/suspensify";
import Loader from "components/Loader";

const TranslateLine = function TranslateLineComponent({ phrase, className }: { phrase: string; className?: string }) {
  const { translatePhrase } = useTranslations();

  const translateLine = useMemo(
    () =>
      suspensify(
        new Promise((resolve) => {
          setTimeout(async () => {
            let translation;
            try {
              translation = await translatePhrase({ phrase, direction: 1 });
            } catch (error) {
              return resolve("");
            }

            return resolve(translation);
          }, 1);
        })
      ),
    [phrase]
  );
  return (
    <span className={className}>
      <React.Suspense
        fallback={
          <span className="w-min float-left ml-1">
            <Loader className="w-[13px] h-[13px] inline mt-[14px] mb-2.5" />
          </span>
        }
      >
        <span className="text-slate-600 not-italic">
          <TriggerSuspense suspense={translateLine} />
        </span>
      </React.Suspense>
    </span>
  );
};

export default TranslateLine;
