import { ForwardIcon, PlayIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { ReadText } from "components/Text";
import useSpeechSynth from "components/useSpeechSynth";
import React from "react";
import { twMerge } from "tailwind-merge";

type PhraseProps = {
  content: { text: string; translation: string };
  speechSynthID: string;
  languageSpeakerID: string;
  onPlayClick: (id: null | string) => void;
  className?: string;
  disablePlay: boolean;
  pointerEvents?: boolean;
};

const Phrase: React.FC<PhraseProps> = function PhraseComponent({
  content: { text, translation },
  speechSynthID,
  languageSpeakerID: speakerID,
  disablePlay,
  className,
  pointerEvents,
  onPlayClick,
}) {
  const { activeID } = useSpeechSynth({
    interrupt: () => {},
    speechSynthID,
    trigger: () => {},
  });
  return (
    <div className={twMerge("flex relative items-start gap-3 select-none", className)}>
      {!pointerEvents && (
        <div
          className="pointer-events-none absolute inset-0 z-40"
          onMouseEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onMouseLeave={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        ></div>
      )}
      <div className="mt-2">
        {activeID === speechSynthID && !disablePlay ? (
          <button
            onClick={() => onPlayClick(null)}
            className="flex items-center justify-center self-center p-1.5 rounded-full text-white bg-red-500 hover:bg-red-600"
          >
            <ForwardIcon className="w-4 h-4" />
          </button>
        ) : (
          <button
            disabled={disablePlay}
            onClick={() => onPlayClick(speechSynthID)}
            className="flex items-center justify-center self-center p-1.5 rounded-full text-white bg-blue-500 hover:bg-blue-600  disabled:bg-slate-300 disabled:text-slate-100"
          >
            <PlayIcon className="w-4 h-4" />
          </button>
        )}
      </div>
      <div>
        <div className="flex items-baseline gap-3">
          <ReadText text={text} speakerID={speakerID} speechSynthID={speechSynthID} />
        </div>
        <div className="align-bottom italic text-sm text-slate-500">{translation}</div>
      </div>
    </div>
  );
};

export default Phrase;
