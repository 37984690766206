import { useMemo, Fragment, useState, useEffect, useCallback } from "react";

import pretreatText, { Token } from "utils/pretreatText";
import SelectableWord from "./SelectableWord";
import classNames from "classnames";
import { SpeechSynthProps } from "components/useSpeechSynth";
import { useExercise } from "components/Lesson/Exercise";

import { twMerge } from "tailwind-merge";
import { useContainer } from "utils/container";
// import { t } from "i18next";
type BlankProps = { number: number; hint?: string };
const Blank = function BlankComponent({ number }: BlankProps) {
  const { data } = useExercise();
  const containerRef = useContainer();
  const [scroll, setScroll] = useState(false);
  const handleClick = useCallback(() => {
    setScroll((prev) => !prev);
  }, [containerRef.current]);
  useEffect(() => {
    if (containerRef?.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [scroll, containerRef]);
  const hint = data?.hints?.[number - 1];
  return (
    <span onClick={handleClick} className="inline-block cursor-pointer bg-white rounded border tracking-normal px-2">
      {number > 0 ? <>({number})</> : ""}______
      {hint && number > 0 ? <span className="text-xs italic tracking-wide">&nbsp;({hint})</span> : ""}
    </span>
  );
};

const tokenToElement = (token: Token, showToken: boolean, disable: boolean = false) => {
  // explicit space for accurate line-breaking!
  if (token.isSpace) return <span className="tracking-widest select-none mx-px inline"> </span>;

  if (token.isBlank) return <Blank number={token.blankIndex} />;

  if (token.isWord)
    return (
      <SelectableWord
        className={classNames({
          "opacity-20": !showToken,
          "opacity-100": showToken,
          "pointer-events-none": !showToken || disable,
          "pointer-events-auto": showToken && !disable,
        })}
        word={token.token}
        disable={!showToken || disable}
      />
    );

  // second-order groupings, word\s<punctuation>, replace whitespace with non-breaking space
  const display = /^\s$/gu.test(token.token[0]) ? <>&nbsp;{token.token.slice(1)}</> : <>{token.token}</>;

  return (
    <span
      className={classNames("select-none mx-px inline text-slate-600 pointer-events-none", {
        "opacity-20": !showToken,
        "opacity-100": showToken,
      })}
    >
      {display}
    </span>
  );
};

type SelectableTextProps = { className?: string; text: string; showUntilIndex?: number } & SpeechSynthProps;

// Displays selectable text including control over words
const SelectableText = function SelectableTextComponent({
  className,
  text,
  showUntilIndex = Number.MAX_VALUE,
}: SelectableTextProps) {
  const tokenGroups = useMemo(() => pretreatText(text), [text]);

  if (!text) return null;

  return (
    <span className={twMerge(className, classNames("relative group select-none"))}>
      {tokenGroups?.map((tokenGroup, groupIndex) => {
        const groupLength = tokenGroup.length;
        let showGroup = true;

        if (groupLength === 1 && !tokenGroup[0].isWord)
          showGroup = tokenGroups
            .slice(groupIndex + 1)
            .some((group) => group.some((token) => token.isWord && token.wordIndex <= showUntilIndex));

        let showUntil =
          groupLength === 1 ? 0 : tokenGroup.findIndex((token) => token.isWord && token.wordIndex <= showUntilIndex);

        const lastWordIndex = tokenGroup.findLastIndex((token) => token.isWord);
        // no more words in the group
        showUntil = lastWordIndex === showUntil && showUntil < groupLength - 1 ? groupLength - 1 : showUntil;
        return (
          <Fragment key={groupIndex}>
            {groupLength > 1 ? (
              <span className="inline-block">
                {tokenGroup.map((token, tkIndex) => (
                  <Fragment key={tkIndex}>{tokenToElement(token, tkIndex <= showUntil)}</Fragment>
                ))}
              </span>
            ) : (
              tokenToElement(tokenGroup[0], tokenGroup[0].wordIndex <= showUntilIndex && showGroup)
            )}
          </Fragment>
        );
      })}
    </span>
  );
};

export default SelectableText;
