import { ArrowLongRightIcon } from "@heroicons/react/24/solid";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import LanguageSelect from "components/form/LanguageSelect";
import ListSelect from "components/form/ListSelect";
import MultiStepForm, { MultiStepItem } from "components/form/MultiStepForm";
import SetupOverview from "./SetupOverview";
import { useCourseContext } from "components/UserInterface/CourseContext";
import { languages, cefrLevels } from "config";
import { CEFRCode } from "utils/cefr";
import { LanguageCode } from "utils/languages";
import { Link, useNavigate } from "react-router-dom";
import { useAPI } from "services/api";
import { StatReturn } from "services/api/routes";
import { XMarkIcon } from "@heroicons/react/24/outline";

export type CourseSetupData = {
  language: LanguageCode;
  level: CEFRCode;
  instruction: LanguageCode;
};

const StepLabel: React.FC<{ label: string }> = ({ label }) => (
  <>
    {label} <ArrowLongRightIcon className="inline w-5 h-5 text-white" />
  </>
);

const CourseSetup: React.FC = function CourseSetupPageComponent() {
  const { language, level, instruction, setupCourse, setupComplete, preconfigureCourse } = useCourseContext();
  const [resetID, setResetID] = React.useState(Math.random());
  const [statData, setStatData] = React.useState<StatReturn | undefined>();
  const resetForm = useCallback(() => setResetID(Math.random()), []);
  const navigate = useNavigate();
  const { services, user } = useAPI();
  const [showMoreCoursesPopup, setShowMoreCoursesPopup] = React.useState(false);

  const { t } = useTranslation();

  const hasAccount = useMemo(() => !!user?.email, [user]);

  useEffect(() => {
    services
      .queryStat({})
      .then(setStatData)
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (hasAccount) return;

    setTimeout(() => setShowMoreCoursesPopup(true), 500);
  }, [hasAccount]);

  useEffect(() => {
    if (!language && !level && !instruction && !setupComplete) return resetForm();
    if (setupComplete) return navigate("/course");
    return () => {};
  }, [setupComplete, language, level, instruction]);

  const languageOptions = useMemo(
    () => languages.map((value) => ({ disabled: value !== "de", selected: value === language, value })),
    [language]
  );

  // const instructionOptions = useMemo(
  //   () =>
  //     instructions.map((value) => ({
  //       disabled: value === language || value !== "en",
  //       selected: value === instruction,
  //       value,
  //     })),
  //   [language, instruction]
  // );

  const levelOptions = useMemo(
    () =>
      cefrLevels.map((value) => ({
        disabled: ["a1", "b1", "b2", "c1", "c2"].includes(value),
        label: t(`courseSetup.cefrDescriptions.${value}`),
        selected: value === level,
        value,
      })),
    [level]
  );

  const handleSubmit = useCallback(() => {}, []);

  const stepData = [
    { title: "language", label: language && <StepLabel label="Level" /> },
    { title: "level", label: level && <StepLabel label="Instruction" /> },
    // { title: "instruction", label: instruction && "Go!" },
    { title: "overview", label: "Go!" },
  ];

  return (
    <>
      {showMoreCoursesPopup && (
        <div
          onClick={() => setShowMoreCoursesPopup(false)}
          className="absolute top-1 inset-x-1 animate-slide-in border border-green-600 rounded flex sm:flex-row gap-2 justify-between bg-green-100 px-4 py-5 shadow items-center"
        >
          <div className="font-semibold grow text-green-700 flex flex-col sm:flex-row gap-2 sm:gap-4 sm:items-center">
            <span>More courses coming soon!</span>{" "}
            <Link
              target="_blank"
              to="https://forms.gle/wE6T3FDK9puLdQRs5"
              className="text-center font-normal leading-6 text-white bg-green-500 hover:bg-green-600 rounded px-2.5 py-0.5"
            >
              Find out first
            </Link>
          </div>
          <div className="absolute right-2 top-2 sm:relative sm:right-auto sm:top-auto self-center">
            <div
              className="cursor-pointer"
              onClick={() => {
                setShowMoreCoursesPopup(false);
              }}
            >
              <XMarkIcon className="text-slate-900 w-5 h-5" />
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col flex-grow overflow-y-auto px-4 py-8 gap-8">
        {!hasAccount && (
          <div className="flex justify-end items-center gap-2">
            <Link to="/signin" className="text-center text-white bg-slate-600 rounded-sm px-2.5 py-0.5 hover:underline">
              Log In
            </Link>
          </div>
        )}
        <div className="flex flex-col flex-grow">
          <MultiStepForm steps={3} reset={resetID} onSubmit={() => handleSubmit()}>
            {({ currentStep, setStep, incrementStep }) => (
              <>
                <h1 className="py-10 font-bold w-full max-w-md mx-auto">
                  {t(`courseSetup.title.${stepData[currentStep].title}`)}
                </h1>
                <MultiStepItem step={0}>
                  <LanguageSelect
                    options={languageOptions}
                    onChange={(value: LanguageCode) => {
                      preconfigureCourse({
                        language: value,
                        instruction: instruction && value === instruction ? undefined : instruction,
                      });
                      setTimeout(() => incrementStep(), 90);
                    }}
                  />
                </MultiStepItem>
                <MultiStepItem step={1}>
                  <ListSelect
                    options={levelOptions}
                    onChange={(value: CEFRCode) => {
                      preconfigureCourse({ level: value });
                      setTimeout(() => incrementStep(), 90);
                    }}
                  />
                </MultiStepItem>
                {/* <MultiStepItem step={2}>
                  <LanguageSelect
                    options={instructionOptions}
                    onChange={(value: LanguageCode) => {
                      if (language && value === language) return;
                      preconfigureCourse({ instruction: value });
                      setTimeout(() => incrementStep(), 90);
                    }}
                  />
                </MultiStepItem> */}
                <MultiStepItem step={2}>
                  <div className="max-w-md relative w-full mx-auto">
                    <SetupOverview />
                    <div
                      onClick={() => {
                        if (setStep) setStep(0);
                      }}
                      className="absolute top-0 right-0 text-sm text-blue-500 hover:underline cursor-pointer"
                    >
                      Change
                    </div>
                    <button
                      className="mt-20 hover:bg-green-600 text-lg shadow-sm font-tutor text-white bg-green-500 py-2 rounded-sm block w-full text-center"
                      onClick={() => {
                        if (!instruction || !language || !level) return setStep?.call(null, 0);
                        setupCourse({ instruction, language, level });
                        return navigate("/course");
                      }}
                    >
                      Start Course
                    </button>
                  </div>
                </MultiStepItem>
              </>
            )}
          </MultiStepForm>
        </div>
        {Boolean(statData?.activeUserCount) && (
          <div className="flex text-xs text-slate-400 text-center p-4 flex-wrap justify-center">
            <div className="font-semibold whitespace-nowrap mr-1">{t("course.next.activeUsers")}</div>
            {statData!.activeUsers.map(({ id, name }) => (
              <>
                <Link className="flex flex-row gap-1 hover:underline whitespace-nowrap" to={`/profile/${id}`} key={id}>
                  {name}
                </Link>
                <div className="mr-1">,</div>
              </>
            ))}
            <div>
              {t("course.next.activeUsersMore", {
                count: statData!.activeUserCount - statData!.activeUsers.length,
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CourseSetup;
