import { useCallback, useEffect, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

import { useAPI } from "services/api";
import { useLesson } from "components/Lesson/LessonContext";
import twm from "utils/twm";
import { useExercise } from ".";
import { ChatBubble, CompleteExerciseButton, ScrollChat } from "./shared";
import { AssistantBubble } from "./shared/DialogueExercise";
import { languageToFullCode } from "./shared/Inputs/TextInput";
import { InputAndControls } from "./shared/InputAndControls";
import { MicrophoneIcon, XMarkIcon } from "@heroicons/react/24/outline";
import handleError from "utils/handleError";
import Medium from "../Medium";
import { useTranslation } from "react-i18next";

interface ListenBubbleProps {
  active?: boolean;
  className?: string;
  disabled?: boolean;
  onClick: () => void;
}

const ListenBubble = ({ active, className, disabled = false, onClick }: ListenBubbleProps) => {
  const Icon = active ? XMarkIcon : MicrophoneIcon;

  return (
    <div className={twm("relative flex flex-col justify-center items-center my-6", className)}>
      <button
        className={twm(
          "flex justify-center items-center w-32 h-32 bg-green-500 hover:bg-green-600 rounded-full disabled:hover:cursor-default disabled:text-gray-400 disabled:bg-gray-300 z-10 hover:cursor-pointer transition-colors",
          { "bg-red-500 hover:bg-red-600": active },
        )}
        onClick={onClick}
        disabled={disabled}
      >
        <Icon className="w-8 h-8 text-white stroke-2" />
      </button>
      <div
        className={twm("absolute w-32 h-32 bg-red-300 rounded-full z-0", {
          "animate-microphone": active,
          hidden: disabled,
        })}
      />
    </div>
  );
};

const speechRepetitionCount = 2;

const SpeechExercise = function SpeechExerciseComponent() {
  const exercise = useExercise();
  const {
    data: { followup },
    directions,
    id: exerciseID,
  } = exercise;
  const {
    onPreComplete,
    lesson: { language },
    vocabularyView,
  } = useLesson();

  const [complete, setComplete] = useState(false);
  const [text, setText] = useState("");
  const [textList, setTextList] = useState<string[]>([]);
  const { browserSupportsSpeechRecognition, isMicrophoneAvailable, listening, resetTranscript, transcript } =
    useSpeechRecognition({});
  const { t } = useTranslation();
  const { services } = useAPI();

  const startListening = useCallback(() => {
    setText("");
    resetTranscript();

    try {
      SpeechRecognition.startListening({ language: languageToFullCode[language] });
    } catch (error) {
      handleError(error);
    }
  }, [language, resetTranscript, setText]);

  const stopListening = useCallback(() => {
    SpeechRecognition.abortListening();
  }, []);

  const toggleListening = useCallback(() => {
    if (listening) stopListening();
    else startListening();
  }, [listening, startListening, stopListening]);

  useEffect(() => {
    resetTranscript();

    return stopListening; // Stop listening on unmount.
  }, []);

  useEffect(() => {
    setText(transcript || "");
  }, [transcript]);

  useEffect(() => {
    if (listening) {
      setText("");
    } else {
      if (text && text.length > 0) setTextList((list) => [...list, text]);
      setText("");
      stopListening();
    }
  }, [listening]);

  const completeExercise = useCallback(
    () =>
      services
        .completeExercise({ exerciseID })
        .then(() => {
          onPreComplete();
          setComplete(true);
        })
        .catch((error: any) => {
          handleError(error);
        }),
    [exerciseID, onPreComplete, services],
  );

  useEffect(() => {
    if (textList.length < speechRepetitionCount) return;

    completeExercise();
  }, [textList]);

  const speechUnavailable = !isMicrophoneAvailable || !browserSupportsSpeechRecognition;

  return (
    <>
      <ScrollChat className={twm("grow justify-between relative", { hidden: vocabularyView })}>
        <div className="h-1"></div>
        {directions && (
          <AssistantBubble
            content={directions}
            className="bg-blue-100 w-full"
            header={t("lesson.exercise.directions")}
            disablePlay={true}
            disableTranslate={exercise.data?.plainDescription}
            plain={exercise.data?.plainDescription}
          ></AssistantBubble>
        )}
        <ChatBubble className="bg-slate-50 w-full">
          <Medium {...exercise.medium} />
        </ChatBubble>
        <AssistantBubble
          content={exercise.data.text}
          speakerID={exercise.data.speakerID}
          plain={exercise.data?.plain}
          disablePlay={exercise.data?.plain}
          disableTranslate={exercise.data?.plain}
          className={twm({ "font-bold bg-slate-100": exercise.data?.plain })}
        ></AssistantBubble>
        {Array.from({ length: speechRepetitionCount }).map((_, i) => (
          <>
            <ChatBubble className={twm("flex flex-row gap-2 bg-green-100 self-end", { hidden: textList.length < i })}>
              <div className="italic text-slate-500">{t("lesson.exercise.speakOutputPrompt")}</div>
              <div>{textList.length > i ? textList[i] : text}</div>
            </ChatBubble>
            <ListenBubble
              className={twm({ hidden: textList.length < i })}
              active={listening}
              disabled={speechUnavailable || textList.length > i}
              onClick={toggleListening}
            />
            {i < speechRepetitionCount - 1 && (
              <AssistantBubble
                className={twm({ hidden: textList.length <= i })}
                content={followup}
                disablePlay={true}
              ></AssistantBubble>
            )}
          </>
        ))}
        {complete && <CompleteExerciseButton />}
        <div className="">&nbsp;</div>
      </ScrollChat>
      <InputAndControls>
        <button
          className={twm(
            "px-4 py-1 text-sm self-center bg-white text-slate-500 rounded border border-transparent hover:border-black hover:text-black",
          )}
          onClick={completeExercise}
        >
          {t("common.cannotSpeak")}
        </button>
      </InputAndControls>
    </>
  );
};

export default SpeechExercise;
