import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Loader from "components/Loader";
import { useCourseContext } from "components/UserInterface/CourseContext";
import Navigation from "components/UserInterface/Navigation";
import { useAPI } from "services/api";
import {
  ListVocabularyCardsResult,
  ScheduleVocabularyCardData,
  ScheduleVocabularyCardResult,
} from "services/api/routes";
import { VocabularyCard } from "services/api/types";
import handleError from "utils/handleError";
import { FaceFrownIcon, FaceSmileIcon, ForwardIcon, XMarkIcon } from "@heroicons/react/24/outline";

interface VocabularyCardProps {
  card: VocabularyCard;
  onComplete?: (args: ScheduleVocabularyCardResult) => void;
}

const VocabCard = ({ card, onComplete }: VocabularyCardProps) => {
  const [reveal, setReveal] = useState(false);
  const [text, setText] = useState("");
  const { t } = useTranslation();
  const { services } = useAPI();

  useEffect(() => {
    setReveal(false);
    setText("");
  }, [card]);

  const handleComplete = async (action: ScheduleVocabularyCardData["rating"]) => {
    let response;
    try {
      response = await services.scheduleVocabularyCard({ vocabularyCardID: card.id, rating: action });
    } catch (error) {
      handleError(error);
      return;
    }

    if (onComplete) onComplete(response);
  };

  return (
    <div className="flex flex-col gap-8 p-6 border border-gray-200 rounded-lg shadow-md">
      <div className="flex flex-col justify-center gap-2 w-full">
        <div className="py-4 text-2xl font-semibold text-center">{card.text}</div>
        {reveal ? (
          <>
            <div className="py-2 text-lg font-light text-center">{reveal ? card.translation : " "}</div>
            <div className="flex flex-col gap-2">
              <div className="font-semibold">Your answer:</div>
              <div>{text}</div>
            </div>
          </>
        ) : (
          <textarea
            rows={3}
            className="border border-gray-200 rounded-md p-2 resize-none"
            placeholder={t("vocab.translationPlaceholder")}
            onChange={(e) => setText(e.target.value)}
            value={text}
          ></textarea>
        )}
      </div>
      {reveal ? (
        <div className="flex flex-col-reverse sm:flex-row gap-4">
          <button
            onClick={handleComplete.bind(null, "again")}
            className="flex flex-row gap-2 items-center justify-center flex-1 px-4 py-2 text-white bg-red-500 hover:bg-red-600 transition-colors rounded-sm select-none text-center cursor-pointer"
          >
            <XMarkIcon className="w-4 h-4 stroke-2" />
            {t("vocab.reschedule.again")}
          </button>
          <button
            onClick={handleComplete.bind(null, "hard")}
            className="flex flex-row gap-2 items-center justify-center flex-1 px-4 py-2 text-white bg-orange-500 hover:bg-orange-600 transition-colors rounded-sm select-none text-center cursor-pointer"
          >
            <FaceFrownIcon className="w-4 h-4 stroke-2" />
            {t("vocab.reschedule.hard")}
          </button>
          <button
            onClick={handleComplete.bind(null, "good")}
            className="flex flex-row gap-2 items-center justify-center flex-1 px-4 py-2 text-white bg-green-500 hover:bg-green-600 transition-colors rounded-sm select-none text-center cursor-pointer"
          >
            <FaceSmileIcon className="w-4 h-4 stroke-2" />
            {t("vocab.reschedule.good")}
          </button>
          <button
            onClick={handleComplete.bind(null, "easy")}
            className="flex flex-row gap-2 items-center justify-center flex-1 px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 transition-colors rounded-sm select-none text-center cursor-pointer"
          >
            <ForwardIcon className="w-4 h-4 stroke-2" />
            {t("vocab.reschedule.easy")}
          </button>
        </div>
      ) : (
        <>
          <button
            onClick={() => setReveal(true)}
            className="px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 disabled:bg-slate-300 disabled:text-slate-100 disabled:cursor-default transition-colors rounded-sm select-none text-center cursor-pointer"
            disabled={!text}
          >
            {t("vocab.revealButtonText")}
          </button>
        </>
      )}
    </div>
  );
};

interface VocabTrainerProps {
  children?: React.ReactNode;
  count: number;
  loading?: boolean;
  onComplete?: (_: ScheduleVocabularyCardResult) => void;
  stack: ListVocabularyCardsResult["vocabularyCards"];
}

export const VocabTrainer = function VocabTrainerComponent({
  children,
  count,
  loading = false,
  onComplete,
  stack,
}: VocabTrainerProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col grow">
      <div className="flex flex-col grow mx-4">
        {loading ? (
          <Loader />
        ) : stack.length > 0 ? (
          <VocabCard onComplete={onComplete} card={stack[0]} />
        ) : (
          <div className="text-xl font-bold text-center px-4 py-16">{t("vocab.noCardsRemaining")}</div>
        )}
      </div>
      {children}
      <div className="flex flex-row gap-1 justify-center py-4 text-slate-500">
        <span className="font-semibold">{count}</span>
        <span>{t("vocab.remainingText")}</span>
      </div>
    </div>
  );
};

const VocabTrainerPage = function VocabTrainerPageComponent() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { services } = useAPI();
  const { language, instruction } = useCourseContext();

  const [stack, setStack] = useState<ListVocabularyCardsResult["vocabularyCards"]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [dueCount, setDueCount] = useState<number>(0);

  const fetchStack = useCallback(async () => {
    setLoading(true);

    let result: any;
    try {
      result = await services.listVocabularyCards({ due: true, language, instruction });
    } catch (error) {
      return;
    } finally {
      setLoading(false);
    }

    setStack(result.vocabularyCards);
    setDueCount(result.count);
  }, [services]);

  useEffect(() => {
    fetchStack();
  }, []);

  return (
    <div className="flex flex-col grow">
      <Navigation />
      <div className="flex flex-row justify-end py-4">
        <button
          onClick={() => navigate("/vocab/stack")}
          className="px-4 py-2 text-slate-600 transition-colors rounded-none select-none cursor-pointer hover:text-blue-600"
        >
          {t("vocab.viewStackTitle")}
        </button>
      </div>
      <VocabTrainer count={dueCount} loading={loading} onComplete={fetchStack} stack={stack} />
    </div>
  );
};

export default VocabTrainerPage;
