import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import HoverPopup from "components/HoverPopup";
import { useAPI } from "services/api";
import { TranslationProvider } from "services/api/TranslationContext";
import Loader from "components/Loader";
import ProgressBar from "components/ProgressBar";
import { useCourseContext } from "components/UserInterface/CourseContext";
import handleError from "utils/handleError";
import { LESSON_CHECKPOINTS } from "./config";
import Exercise from "./Exercise";
import LessonContext from "./LessonContext";
import LessonSummary from "./LessonSummary";

type LessonProps = { lessonID: string; onExit?: () => void; startFromBeginning?: boolean };

const Lesson: React.FC<LessonProps> = function LessonComponent({ lessonID, onExit, startFromBeginning = false }) {
  const [lessonData, setLessonData] = useState<any>(null);
  const [audio, setAudio] = useState<boolean>(true);
  const [exercisePointer, setExercisePointer] = useState(0);
  const [progressOffset, setProgressOffset] = useState(0);
  const [vocabularyView, setVocabularyView] = useState(false);
  const { language, instruction } = useCourseContext();

  const { accessToken, services } = useAPI();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!lessonID) return () => {};

    setExercisePointer(0);

    (async () => {
      let lesson: any;
      try {
        lesson = await services.queryLesson({ lessonID });
      } catch (error) {
        handleError(error);
        return;
      }

      setLessonData(lesson);

      if (!accessToken || startFromBeginning) return;

      const lessonStats = await services.queryLessonStats({ lessonID });

      if (lessonStats.solvedCount >= lessonStats.exerciseCount) {
        navigate("summary", { replace: true });
        return;
      }

      const solvedPercentage = (lessonStats.solvedCount / lessonStats.exerciseCount) * 100;
      const checkpoint = LESSON_CHECKPOINTS.findLast((cp) => solvedPercentage >= cp);

      if (!checkpoint || checkpoint < 0) return;

      const exerciseIndex = lesson.exercises.findIndex(
        (_: any, i: number) => (i / lesson.exercises.length) * 100 >= checkpoint
      );

      setExercisePointer(exerciseIndex);
      setLessonData(lesson);
    })();

    return () => {
      setLessonData(null);
    };
  }, [lessonID, startFromBeginning]);

  const exercises = useMemo(() => lessonData?.exercises, [lessonData]);

  const handleComplete = useCallback(async () => {
    if (exercisePointer >= exercises.length - 1) {
      await Promise.all(
        lessonData.vocabulary.map((v: any) =>
          services
            .createVocabularyCard({
              instruction: lessonData.instruction,
              language: lessonData.language,
              text: v.text,
              translation: v.translation,
            })
            .catch(() => {})
        )
      );

      if (!accessToken) navigate("/course");
      else navigate("summary", { replace: true });

      return;
    }

    setProgressOffset(0);
    setExercisePointer((seq) => seq + 1);
  }, [accessToken, exercises, exercisePointer, lessonData, setExercisePointer, setProgressOffset]);

  const handlePreComplete = useCallback(() => setProgressOffset(1), [setProgressOffset]);
  const toggleAudio = useCallback(() => setAudio((prev) => !prev), [setAudio]);
  const toggleVocabularyView = useCallback(() => {
    setVocabularyView((prev) => !prev);
  }, [setVocabularyView]);
  const summaryPage = location.pathname.endsWith("summary");
  const completedExercises = useMemo(() => exercisePointer + progressOffset, [exercisePointer, progressOffset]);
  const lessonProgress = useMemo(() => {
    if (!lessonData) return 0;
    if (summaryPage) return 100;

    const totalExercises = lessonData.exercises.length;
    const progress = (completedExercises / totalExercises) * 100;

    return Math.min(progress, 100);
  }, [completedExercises, lessonData]);

  const lessonContext = useMemo(
    () => ({
      exercisePointer,
      lesson: lessonData,
      lessonProgress,
      audio,
      toggleVocabularyView,
      vocabularyView,
      toggleAudio,
      onComplete: handleComplete,
      onPreComplete: handlePreComplete,
    }),
    [
      exercises,
      exercisePointer,
      lessonData,
      lessonProgress,
      audio,
      toggleAudio,
      handleComplete,
      handlePreComplete,
      toggleVocabularyView,
      vocabularyView,
    ]
  );

  if (!language || !instruction) return null;
  return (
    <div className="flex flex-col flex-grow overflow-y-hidden font-tutor leading-10">
      {lessonData ? (
        <LessonContext.Provider value={lessonContext}>
          <TranslationProvider from={language} to={instruction}>
            <div className="flex flex-grow flex-col w-full overflow-y-hidden">
              <div className="flex items-center bg-white py-3 px-2 gap-3 sm:px-5 shadow-sm h-16 border-b border-gray-100">
                <button className="cursor-pointer text-slate-500 hover:text-red-600 transition-colors" onClick={onExit}>
                  <ArrowRightOnRectangleIcon className="w-6 h-6 self-center transform rotate-180" />
                </button>
                <div className="flex flex-grow flex-col">
                  <HoverPopup caret={false} popup={`Lesson progress: ${lessonProgress.toFixed(0)}%`}>
                    <ProgressBar checkpoints={LESSON_CHECKPOINTS} progress={summaryPage ? 100 : lessonProgress} />
                  </HoverPopup>
                </div>
                <HoverPopup
                  caret={false}
                  className="text-slate-500 text-md cursor-pointer hover:text-blue-500 transition-colors"
                  popup={`${summaryPage ? lessonData.exercises.length : completedExercises} out of ${
                    lessonData.exercises.length
                  } exercises completed.`}
                  popupClassName="text-black"
                >
                  {summaryPage ? lessonData.exercises.length : completedExercises}/{lessonData.exercises.length}
                </HoverPopup>
              </div>
              <Routes>
                <Route
                  index
                  element={<Exercise exerciseID={exercises[Math.min(exercisePointer, exercises.length - 1)]} />}
                ></Route>
                <Route path="summary" element={<LessonSummary />} />
              </Routes>
            </div>
          </TranslationProvider>
        </LessonContext.Provider>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Lesson;
