import { useEffect, useState } from "react";

import Textarea from "components/shared/components/Textarea";
import { loadImage } from "data";
import { useTranslations } from "services/api/TranslationContext";
import { LanguageCode, languageCodeToFlag } from "utils/languages";
import useDebounce from "utils/useDebounce";
import { useCourseContext } from "components/UserInterface/CourseContext";

const loadImageFromCode = (code: LanguageCode | undefined) => (code ? loadImage(languageCodeToFlag(code)) : "");

const TranslateInput = function TranslateInputComponent() {
  const { instruction, language } = useCourseContext();
  const [focus, setFocus] = useState<"from" | "to" | "">("from");

  const { translatePhrase } = useTranslations();
  const { debounce: debounceTranslation } = useDebounce({ delay: 800 });

  const [translationState, setTranslationState] = useState({ to: "", from: "" });

  useEffect(() => {
    if (/^\s*$/gu.test(translationState.from) && /^\s*$/gu.test(translationState.to)) return () => {};
    if (/^\s*$/gu.test(translationState.from)) {
      debounceTranslation(() => {
        if (focus === "from") return;
        translatePhrase({ phrase: translationState.to.trim(), direction: -1 }).then((translation) => {
          setTranslationState((prev) => ({ ...prev, from: translation }));
        });
      });
      return () => {};
    }

    debounceTranslation(() => {
      if (focus === "to") return;
      translatePhrase({ phrase: translationState.from.trim(), direction: 1 }).then((translation) => {
        setTranslationState((prev) => ({ ...prev, to: translation }));
      });
    });
    return () => {};
  }, [translationState, translatePhrase]);

  return (
    <div className="flex grow flex-col items-stretch text-sm space-y-1 tracking-wide">
      <div className="relative w-full flex gap-2 pl-2 rounded-md items-start border border-slate-200">
        <img src={loadImageFromCode(language)} alt="flag" className="w-[21px] shadow-sm shrink-0 mt-1.5" />
        <Textarea
          onChange={(e) => {
            setTranslationState({
              to: "",
              from: e.target.value,
            });
          }}
          onFocus={(e) => {
            if (focus === "from") return;
            e.currentTarget.select();
            setFocus("from");
          }}
          rows={1}
          value={translationState.from}
          className="py-1 mx-2 box-border rounded-md grow outline-none select-all resize-none"
          placeholder={"Type for translation..."}
        />
      </div>
      <div className="flex relative w-full gap-2 pl-2 rounded-md items-start border border-slate-200">
        <img src={loadImageFromCode(instruction)} alt="flag" className="w-[21px] shadow-sm shrink-0 mt-1.5" />
        <Textarea
          onChange={(e) => {
            setTranslationState({
              from: "",
              to: e.target.value,
            });
          }}
          onFocus={(e) => {
            if (focus === "to") return;

            e.currentTarget.select();
            setFocus("to");
          }}
          value={translationState.to}
          className="py-1 mx-2 rounded-md grow outline-none select-all resize-none"
          placeholder={"Type for translation..."}
        />
      </div>
    </div>
  );
};

export default TranslateInput;
