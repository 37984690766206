import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";

import useSpeechSynth from "components/useSpeechSynth";
import { MediumTypeProps } from "./types";
import { PlayIcon, StopIcon } from "@heroicons/react/24/solid";
import { useLesson } from "../LessonContext";
import { useAudio } from "utils/audio";

interface SoundData {
  description: string;
  file: string;
  transcript: string;
}

interface SoundMediumProps extends MediumTypeProps {
  data: SoundData;
}

const SoundMedium: React.FC<SoundMediumProps> = function SoundMediumComponent({ data, speechSynthID }) {
  const [finishedOnce, setFinishedOnce] = useState(false);
  const [play, setPlay] = useState(false);
  const { audio: volume } = useLesson();
  const { file } = useMemo(() => data, [data]);

  const { onAudioEnd, pauseAudio, playAudio, setAudioVolume } = useAudio();

  const { onSpeechSynthComplete } = useSpeechSynth({
    interrupt: () => setPlay(false),
    speechSynthID,
    trigger: () => setPlay(true),
  });

  useEffect(() => {
    if (!play) return () => {};

    playAudio(file);
    setAudioVolume(volume ? 1 : 0, data.file);
    onAudioEnd(() => setPlay(false));

    if (finishedOnce) return () => pauseAudio(data.file);

    setFinishedOnce(true);

    return () => {
      onSpeechSynthComplete();
      pauseAudio(data.file);
    };
  }, [play]);

  useEffect(() => {
    setAudioVolume(volume ? 1 : 0, data.file);
  }, [volume]);

  return (
    <div className="">
      <div className="flex items-center p-2 my-2 bg-slate-900 bg-opacity-50 rounded-md gap-6 min-w-[200px] w-1/3 mx-auto">
        <button
          className={classNames("relative text-white transition-colors hover:text-slate-100")}
          onClick={() => setPlay((prev) => !prev)}
        >
          {play ? <StopIcon className="w-9 h-9" /> : <PlayIcon className="w-9 h-9" />}
        </button>
        <div className="grow overflow-hidden mr-5 relative flex items-center justify-start h-4">
          {play ? (
            <div className="flex justify-center items-center gap-px absolute left-0 top-0 h-4">
              {Array.from(Array(100)).map((_, i) => (
                <div
                  key={i}
                  style={{ animationDuration: `${Math.random() * (1 - 0.2) + 0.2}s` }}
                  className={
                    "w-px h-2 animate-wave-lg [&:nth-child(-n+3)]:animate-wave-md [&:nth-last-child(-n+3)]:animate-wave-md [&>:nth-child(-n+7)]:animate-wave-sm [&>:nth-last-child(-n+7)]:animate-wave-sm bg-white"
                  }
                >
                  {" "}
                </div>
              ))}
            </div>
          ) : (
            <div className="h-px bg-white grow" />
          )}
        </div>
      </div>
    </div>
  );
};

export default SoundMedium;
