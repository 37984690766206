import React, { useContext, useEffect, useMemo, useState } from "react";

import VocabularyComponent from "components/Lesson/VocabularyComponent";
import { useLesson } from "components/Lesson/LessonContext";
import Loader from "components/Loader";
import { useAPI } from "services/api";
import twm from "utils/twm";
import ConversationExercise from "./ConversationExercise";
import ExerciseContext from "./ExerciseContext";
import OpenQuestionExercise from "./OpenQuestionExercise";
import PassiveExercise from "./PassiveExercise";
import FillInExercise from "./FillInExercise";
import { ExerciseContextType } from "./types";
import SpeechExercise from "./SpeechExercise";

const exerciseComponents: { [key: string]: React.FC } = {
  conversation: ConversationExercise,
  "open-question": OpenQuestionExercise,
  "fill-in": FillInExercise,
  passive: PassiveExercise,
  speech: SpeechExercise,
};

type ExerciseProps = { exerciseID: string };

const Exercise = function ExerciseComponent({ exerciseID }: ExerciseProps) {
  const [exerciseData, setExerciseData] = useState<any>(null);
  const { services } = useAPI();

  const { onComplete, vocabularyView } = useLesson();

  useEffect(() => {
    setExerciseData(null);

    if (!exerciseID) return;

    (async () => {
      setExerciseData(await services.queryExercise({ exerciseID }));
    })();
  }, [exerciseID]);

  const ExerciseTypeComponent: React.FC = useMemo(() => exerciseComponents[exerciseData?.type], [exerciseData]);
  const context = useMemo<ExerciseContextType>(() => ({ ...exerciseData, onComplete }), [exerciseData, onComplete]);

  if (!exerciseData) return <Loader />;

  return (
    <ExerciseContext.Provider value={context}>
      <div className={twm("grow flex flex-col overflow-y-hidden", { hidden: !vocabularyView })}>
        <VocabularyComponent />
      </div>
      <ExerciseTypeComponent />
    </ExerciseContext.Provider>
  );
};

const useExercise = () => {
  const context = useContext(ExerciseContext);

  return context;
};

export default Exercise;
export { useExercise };
