import { UserCircleIcon, HomeIcon, RectangleStackIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ConfirmPopup from "components/Popup/ConfirmPopup";
import { useCourseContext } from "components/UserInterface/CourseContext";
import { LanguageField } from "components/UserInterface/CourseSetup/CourseSelection";
import { loadImage } from "data";
import { useAPI } from "services/api";
import twm from "utils/twm";

const NavLink = ({ to, children }: { to: string; children: React.ReactNode }) => {
  const location = useLocation();

  return (
    <Link
      to={to}
      className={twMerge(
        classNames(
          "flex flex-shrink-0 items-center cursor-pointer border-b-2 border-transparent text-blue-600 transition-colors",
          {
            " border-blue-600": location.pathname === to,
          }
        )
      )}
    >
      {children}
    </Link>
  );
};

const Navigation: React.FC = function NavigationComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const { clearCourse, language, setupComplete } = useCourseContext();

  const { user } = useAPI();
  const { t } = useTranslation();

  const handleReset = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPopup
          icon={<div className="relative flex grow gap-4 items-center"></div>}
          onCancel={onClose}
          onConfirm={async () => {
            clearCourse();
            onClose();
            navigate("/setup", { state: { reset: Math.random() } });
          }}
        >
          {t("navigation.reset.message")}
        </ConfirmPopup>
      ),
    });
  };

  return (
    <nav className="flex flex-shrink-0 border-b border-gray-100 py-3 px-2 sm:px-5 items-stretch justify-center flex-wrap gap-5 h-16">
      <div className="flex-1 shrink-0 flex-nowrap italic text-slate-800 text-sm items-center hidden sm:flex sm:justify-start">
        <img src={loadImage("logo.svg")} alt="logo" className="inline h-5 w-5 mr-2" />
        <span>Respeakable</span>
      </div>
      {setupComplete ? (
        <>
          <div className="flex flex-1 sm:justify-center">
            <>
              <div
                className="flex flex-shrink-0 flex-col px-4 py-3 sm:py-2 cursor-pointer bg-slate-100 hover:bg-slate-200 transition-colors rounded-full"
                onClick={handleReset}
              >
                <LanguageField languageCode={language} />
              </div>
            </>
          </div>
          <div className="flex sm:flex-1 justify-end gap-4 items-center">
            <Link
              to="/course"
              className={twm("text-slate-600 hover:text-blue-500", {
                "text-blue-500": location.pathname === "/course",
              })}
            >
              <HomeIcon className="w-7 h-7" />
            </Link>
            <Link
              to="/vocab"
              className={twm("text-slate-600 hover:text-blue-500", {
                "text-blue-500": location.pathname === "/vocab",
              })}
            >
              <RectangleStackIcon className="w-7 h-7" />
            </Link>
            {user?.email ? (
              <Link
                to={`/profile/${user?.id}`}
                className={twm("text-slate-600 hover:text-blue-500", {
                  "text-blue-500": location.pathname === `/profile/${user?.id}`,
                })}
              >
                <UserCircleIcon className="w-7 h-7" />
              </Link>
            ) : (
              <Link to="/signup" className="text-white py-1 px-2 bg-slate-600 rounded-sm">
                Sign Up
              </Link>
            )}
          </div>
        </>
      ) : (
        <div className="flex flex-1 justify-center sm:justify-end hover:underline text-blue-600">
          <NavLink to="/setup">{t("courseSetup.try")}</NavLink>
        </div>
      )}
    </nav>
  );
};

export default Navigation;
