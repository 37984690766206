import { MicrophoneIcon, PaperAirplaneIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { twMerge } from "tailwind-merge";

import { useExercise } from "components/Lesson/Exercise";
import { useLesson } from "components/Lesson/LessonContext";
import Textarea from "components/shared/components/Textarea";
import { on } from "events";
import handleError from "utils/handleError";

type TextInputButtonProps = {
  colorClassName?: string;
  disabled?: boolean;
  icon: React.ElementType;
  onClick: () => void;
  pulse?: boolean;
  pulseColor?: string;
};

export const languageToFullCode: { [key: string]: string } = {
  en: "en-US",
  de: "de-DE",
  es: "es-ES",
  fr: "fr-FR",
  it: "it-IT",
  pt: "pt-PT",
  ru: "ru-RU",
  zh: "zh-CN",
};

const TextInputButton = function TextInputButtonComponent({
  colorClassName = "bg-green-600 hover:bg-green-700 text-white",
  disabled = false,
  pulseColor = "bg-green-600",
  icon: IconComponent,
  onClick,
  pulse = false,
}: TextInputButtonProps) {
  const handleClick = useCallback(() => {
    onClick?.();
  }, [onClick, pulse]);

  return (
    <button
      className="flex flex-grow-0 flex-shrink-0 w-8 justify-center items-center"
      disabled={disabled}
      onClick={handleClick}
      type="button"
    >
      <span
        className={twMerge(
          classNames("flex rounded-full w-8 h-8 z-10", {
            [classNames("bg-white bg-opacity-50", pulseColor)]: !disabled && pulse,
            [colorClassName]: !pulse,
            "text-gray-400 bg-gray-300 hover:bg-gray-300": disabled,
          })
        )}
      >
        <IconComponent className="p-2" />
      </span>
      {!disabled && pulse && (
        <span className={twMerge("absolute rounded-full animate-microphone z-0 w-8 h-8", colorClassName)}></span>
      )}
    </button>
  );
};

export type TextInputProps = {
  autoListen?: boolean;
  disabled?: boolean;
  onChange?: (text: string) => void;
  onSubmit?: (text: string) => void;
  onFocus?: (state: boolean) => void;
  placeholder?: string;
  placeholderSpeak?: string;
  expectingInput?: boolean;
};

const TextInput = forwardRef<HTMLTextAreaElement, TextInputProps>(
  (
    {
      autoListen = false,
      disabled = false,
      onChange,
      onSubmit,
      placeholder,
      placeholderSpeak,
      expectingInput,
      onFocus,
    },
    ref
  ) => {
    const [text, setText] = useState("");
    const {
      lesson: { language },
    } = useLesson();
    const { browserSupportsSpeechRecognition, isMicrophoneAvailable, listening, resetTranscript, transcript } =
      useSpeechRecognition({});
    const exercise = useExercise();

    const startListening = useCallback(() => {
      setText("");
      resetTranscript();

      try {
        SpeechRecognition.startListening({ language: languageToFullCode[language] });
      } catch (err) {
        handleError(err);
      }
    }, [language, resetTranscript, setText]);

    const stopListening = useCallback(() => {
      SpeechRecognition.abortListening();
    }, []);

    useEffect(() => {
      setText("");
      resetTranscript();

      return stopListening; // Stop listening on exercise switch.
    }, [exercise]);

    useEffect(() => {
      setText(transcript);
    }, [setText, transcript]);

    useEffect(() => {
      if (!expectingInput) {
        setText("");
        stopListening();
        return;
      }

      if (autoListen) startListening();
    }, [expectingInput]);

    useEffect(() => {
      if (!disabled) return;

      setText("");
      stopListening();
    }, [disabled]);

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;

        setText(value);

        if (onChange) onChange(value);
      },
      [setText, onChange]
    );

    const handleSubmit = useCallback(() => {
      if (text === "") return;
      if (onSubmit) onSubmit(text);

      setText("");
      stopListening();
    }, [onSubmit, setText, stopListening, text]);

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key !== "Enter" || e.shiftKey) {
          return;
        }

        e.preventDefault();
        e.stopPropagation();

        handleSubmit();
      },
      [handleSubmit]
    );

    const toggleSpeechInput = useCallback(listening ? stopListening : startListening, [
      startListening,
      stopListening,
      listening,
    ]);

    return (
      <span
        className={classNames("flex flex-grow py-1 pr-2 gap-4 box-border border rounded-md items-end", {
          "text-gray-400": disabled,
          "border-2 border-orange-300 box-content -m-px": listening && !disabled,
        })}
      >
        <Textarea
          className={classNames("flex-grow min-w-0 outline-none pl-4 pr-2 py-2 font-sans leading-normal resize-none", {
            "text-slate-400": disabled,
          })}
          disabled={disabled}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={() => {
            stopListening();
            onFocus?.(true);
          }}
          onBlur={() => {
            onFocus?.(false);
          }}
          placeholder={listening ? placeholderSpeak : placeholder}
          ref={ref}
          value={text}
        />
        <div className="my-1 flex gap-2">
          <TextInputButton
            colorClassName="bg-orange-600 hover:bg-orange-700 text-white"
            disabled={!listening && (disabled || !browserSupportsSpeechRecognition || !isMicrophoneAvailable)}
            icon={MicrophoneIcon}
            onClick={() => toggleSpeechInput()}
            pulse={listening}
            pulseColor="text-orange-600"
          />
          <TextInputButton disabled={disabled} icon={PaperAirplaneIcon} onClick={handleSubmit} />
        </div>
      </span>
    );
  }
);

export default TextInput;
